import React from "react";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input, Button,
    Table
} from "reactstrap"


import Griddle, {ColumnDefinition, plugins, RowDefinition} from 'griddle-react';
import { connect } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const rowDataSelector = (state, { griddleKey }) => {
    return state
        .get('data')
        .find(rowMap => rowMap.get('griddleKey') === griddleKey)
        .toJSON();
};

const enhancedWithRowData = connect((state, props) => {
    return {
        // rowData will be available into MyCustomComponent
        rowData: rowDataSelector(state, props)
    };
});


const dateObj = new Date();
const year = dateObj.getUTCFullYear();
const yesterdayObj = new Date(dateObj.getTime() - 60 * 60 * 24 * 1000)
const tomorrowObj = new Date(dateObj.getTime() + 60 * 60 * 24 * 1000)

const yesterday = yesterdayObj.getUTCFullYear() + "-" + to2digits(yesterdayObj.getUTCMonth() + 1) + "-" + to2digits(yesterdayObj.getUTCDate())
const today = dateObj.getUTCFullYear() + "-" + to2digits(dateObj.getUTCMonth() + 1) + "-" + to2digits(dateObj.getUTCDate())
const tomorrow = tomorrowObj.getUTCFullYear() + "-" + to2digits(tomorrowObj.getUTCMonth() + 1) + "-" + to2digits(tomorrowObj.getUTCDate())

export const NewLayout = ({ Table, Pagination, Filter, SettingsWrapper }) => (
    <div>
        <Filter />
        <Table />
        <Pagination />
    </div>
);

export const styleConfig = {
    styles: {
        Filter: {
            fontSize: 18,
            margin: "10px 0 0 80%"
        },
        Pagination: {
            fontSize: 18,
            margin: "10px 0 10px 80%"
        },
        NextButton: {
            marginLeft: 10
        },
        PreviousButton: {
            marginRight: 10
        },
        Table: {
            fontSize: 18,
            margin: "10px auto",
            width: "75%"
        },
        Row : {
            marginBottom: 5,
            height:60,
        },
        TableHeading: {
            borderBottom: "1px solid rgb(78, 84, 200)",
        }
    }
}

function MyCustomComponent({ value, griddleKey, rowData }) {
    return (
        <div className="MyCustomComponent">
            {new Intl.NumberFormat('ru-RU').format(value)}
        </div>
    );
}


class Statistic extends React.Component{

    state = {
        dateFrom : today,
        dateTo : tomorrow,
        comparisonPeriod: 1,
        items: [],
        loaded:false,
        mounted : false,
        sum : {
            'reqs' : 0,
            'wins' : 0,
            'imps' : 0,
            'clicks' : 0,
            'spent' : 0,
        },
        comparison : {
            'reqs' : 0,
            'imps' : 0,
            'clicks' : 0,
            'spent' : 0,
        },
        comparisonLoaded : false,
        campaign : ""

    };


    componentDidMount() {
        this.loadStat();
        this.loadComparison();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.state.dateFrom !== prevState.dateFrom || this.state.dateTo !== prevState.dateTo) {
            this.loadStat();
        }
        if (this.state.dateTo !== prevState.dateTo || this.state.comparisonPeriod !== prevState.comparisonPeriod) {
            this.loadComparison()
        }
    }



    loadStat = () => {

        console.log(this.state)

        this.setState({
            items: [],
            loaded:false,
            sum : {
                'reqs' : 0,
                'wins' : 0,
                'imps' : 0,
                'clicks' : 0,
                'spent' : 0,
            },})
        fetch(`/api?function=getStatistic&dateFrom=${this.state.dateFrom}&dateTo=${this.state.dateTo}`,)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({loaded:true,items:result});
                    result.map((row) => {
                        this.setState({sum:{
                                'reqs' : this.state.sum.reqs += row.reqs,
                                'clicks' : this.state.sum.clicks += row.clicks,
                                'wins' : this.state.sum.wins += row.wins,
                                'imps': this.state.sum.imps += row.imps,
                                'spent' : this.state.sum.spent += row.spent,
                            }});
                    })
                },
                // Примечание: Обрабатывать ошибки необходимо именно здесь
                // вместо блока catch(), чтобы не пропустить
                // исключения из реальных ошибок в компонентах.
                (error) => {
                    this.setState({loaded:true})
                }
            )
    }

    loadComparison = () => {

        this.setState({
            comparisonLoaded : false,
            comparison : {
                'reqs' : 0,
                'imps' : 0,
                'clicks' : 0,
                'spent' : 0,
            },
            })
        fetch(`/api?function=getComparison&period=${this.state.comparisonPeriod}&dateTo=${this.state.dateTo}`,)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({comparisonLoaded:true});
                    this.setState({comparison:{
                            'reqs' : result.reqs,
                            'clicks' : result.clicks,
                            'imps': result.imps,
                            'spent' : result.spent,
                        }
                    });
                },
                // Примечание: Обрабатывать ошибки необходимо именно здесь
                // вместо блока catch(), чтобы не пропустить
                // исключения из реальных ошибок в компонентах.
                (error) => {
                    this.setState({loaded:true})
                }
            )
    }



    render() {

        return (
            <div className={"content"}>
                <Row>
                    <Card className={"custom-date first-row"}>
                        <Paper className={"paper-stat"}>
                            <div className="left">
                                Reqs:
                            </div>
                            <div className="right">
                                {Math.abs(this.state.comparison.reqs)}%
                                {this.state.comparison.reqs < 0 ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/> }
                            </div>
                        </Paper>
                        <Paper className={"paper-stat"}>
                            <div className="left">
                                Imps:
                            </div>
                            <div className="right">
                                {Math.abs(this.state.comparison.imps)}%
                                {this.state.comparison.imps < 0 ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/> }
                            </div>
                        </Paper>
                        <Paper className={"paper-stat"}>
                            <div className="left">
                                Clicks:
                            </div>
                            <div className="right">
                                {Math.abs(this.state.comparison.clicks)}%
                                {this.state.comparison.clicks < 0 ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/> }
                            </div>
                        </Paper>
                        <Paper className={"paper-stat"}>
                            <div className="left">
                                Spent:
                            </div>
                            <div className="right">
                                {Math.abs(this.state.comparison.spent)}%
                                {this.state.comparison.spent < 0 ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/> }
                            </div>
                        </Paper>
                    </Card>
                </Row>
                <Row>
                    <Card className={"custom-date"}>
                        <div className={"custom-col"}>
                            <div>Select first day</div>
                            <Input
                                type={"date"}
                                value={this.state.dateFrom}
                                onChange={e => this.setState({dateFrom:e.target.value})}
                            />
                        </div>
                        <div className={"custom-col"}>
                            <div>Select last day</div>
                            <Input
                                type={"date"}
                                value={this.state.dateTo}
                                onChange={e => this.setState({dateTo:e.target.value})}
                            />
                        </div>
                        <div className={"custom-col"}>
                            <div>Select comparison period</div>
                            <Select
                                id="comparison-period"
                                value={this.state.comparisonPeriod}
                                onChange={e => this.setState({comparisonPeriod : e.target.value })}
                            >
                                <MenuItem value={1}>1 Day</MenuItem>
                                <MenuItem value={7}>7 Days</MenuItem>
                                <MenuItem value={30}>30 Days</MenuItem>
                            </Select>
                        </div>
                    </Card>
                </Row>
                <Row>
                    <Card>
                        <Griddle
                            plugins={[plugins.LocalPlugin]}
                            data={this.state.items}
                            components={{Layout:NewLayout}}
                            styleConfig={styleConfig}
                        >
                            <RowDefinition>
                                <ColumnDefinition
                                    id="name"
                                    title="Description"
                                    order={1}
                                    width={400}
                                />
                                <ColumnDefinition
                                    id="spent"
                                    title="Spent, $"
                                    cssClassName={"col-right"}
                                    headerCssClassName={"col-right"}
                                    customComponent={enhancedWithRowData(MyCustomComponent)}
                                />
                                <ColumnDefinition
                                    id="reqs"
                                    title="Requests"
                                    cssClassName={"col-right"}
                                    headerCssClassName={"col-right"}
                                    customComponent={enhancedWithRowData(MyCustomComponent)}
                                />
                                <ColumnDefinition
                                    id="imps"
                                    title="Hits"
                                    cssClassName={"col-right"}
                                    headerCssClassName={"col-right"}
                                    customComponent={enhancedWithRowData(MyCustomComponent)}
                                />
                                <ColumnDefinition
                                    id="clicks"
                                    title="Clicks"
                                    cssClassName={"col-right"}
                                    headerCssClassName={"col-right"}
                                    customComponent={enhancedWithRowData(MyCustomComponent)}
                                />
                            </RowDefinition>
                        </Griddle>
                    </Card>
                </Row>

            </div>
        )
    }
}


export default Statistic

function to2digits(number) {
    if (number < 10)
        return '0' + number;

    return number
}
