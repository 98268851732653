import React, {useEffect} from 'react';

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    UncontrolledTooltip
} from "reactstrap";


import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { thead, tbody } from "variables/general";
import Griddle, {ColumnDefinition, plugins, RowDefinition} from "griddle-react";
import {styleConfig,NewLayout} from "./Statistic"

class Finance extends React.Component{

    state = {
        result : {},
        loaded : false,
        error : false,
        rowsPerPage:20,
        page:0
    };

    componentDidMount() {
        fetch('/api?function=getFinance',)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({result:result});
                    this.setState({loaded:true});
                },
                (error) => {
                    this.setState({loaded:true});
                    this.setState({error:error})
                }
            );
    }

    nextPage = () => {
        this.setState({page:this.state.page + 1});
        console.log((this.state.page))
    };
    prevPage = () => {
        if (this.state.page > 0) {
            this.setState({page:this.state.page - 1})
        }
    };

    drawAct = (date) => {
        return <span style={{ color: '#0000AA' }}>{date}</span>
    }


    render() {

        if (this.state.error) {
            return (
                <>
                    <div className="content">
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardTitle>
                                        <h4 align="center">Payments history</h4>
                                    </CardTitle>
                                    <CardBody>
                                        <div>Error : {this.error.message}</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        } else if (!this.state.loaded) {
            return (
                <>
                    <div className="content">
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardTitle>
                                        <h4 align="center">Payments history</h4>
                                    </CardTitle>
                                    <CardBody>
                                        <div>Loading ...</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        }else{
            return (
                <>
                    <div className="content">
                        <CurrentBalance/>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <CardTitle>
                                            <h4 align="center">Payments history</h4>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody className={"finance-table"}>
                                        <Griddle
                                            plugins={[plugins.LocalPlugin]}
                                            data={this.state.result}
                                            components={{Layout:NewLayout}}
                                            styleConfig={styleConfig}
                                        >
                                            <RowDefinition>
                                                <ColumnDefinition
                                                    id="date"
                                                    title="Action date"
                                                    order={1}
                                                />
                                                <ColumnDefinition
                                                    id="amount"
                                                    title="Amount"
                                                    cssClassName={"col-right"}
                                                    headerCssClassName={"col-right"}
                                                />
                                                <ColumnDefinition
                                                    id="currency"
                                                    title="Currency"
                                                />
                                                <ColumnDefinition
                                                    id="comment"
                                                    title="Type"
                                                />
                                                <ColumnDefinition
                                                    id="act"
                                                    title="Document"
                                                    customComponent={drawAct}
                                                />

                                            </RowDefinition>
                                            </Griddle>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )

        }
    }

}

export default Finance

class Actions extends React.Component{
    render() {
        const linkAct = `/api?function=getStats&date=${this.props.date}`;


        /*
        * <Col xs={1} className="ml-5">
                    <a href={linkStats}  id="stat">
                        <Button color="info" className="d-flex align-items-center">Statistic&nbsp;&nbsp;<i className="now-ui-icons arrows-1_cloud-download-93" /></Button>
                    </a>
                    <UncontrolledTooltip placement="top" target="stat" delay={0}>
                        Download Statistics
                    </UncontrolledTooltip>
                </Col>
        * */

        return (
            <Row className="m-auto">
                <Col xs={1}>
                    <a href={linkAct} id="act" className="text-decoration-none">
                        <Button color="primary" className="d-flex align-items-center"><i className="now-ui-icons arrows-1_cloud-download-93" /></Button>
                    </a>
                    <UncontrolledTooltip placement="top" target="act" delay={0}>
                       Download Act
                    </UncontrolledTooltip>
                </Col>

            </Row>


        )
    }
}

class CurrentBalance extends React.Component {
    state = {
        loaded : false,
        balance : 0
    }

    componentDidMount() {
        this.loadBalance()
    }

    loadBalance = ()=> {
        fetch(`/api?function=getBalance`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({loaded:true,balance:result.balance});
                },
                // Примечание: Обрабатывать ошибки необходимо именно здесь
                // вместо блока catch(), чтобы не пропустить
                // исключения из реальных ошибок в компонентах.
                (error) => {
                    this.setState({loaded:true})
                }
            )
    }

    render() {
        return (
            <Row>
                <Col xs={12}>
                    <Card className={"card-balance"}>
                        Your current balance: {this.state.balance}$
                    </Card>
                </Col>
            </Row>
        );
    }

}

function drawAct({ value, griddleKey, rowData }) {
    if (value === null) {
        return null
    }

    let link = `/api?function=getStats&date=${value}`

    return (
        <div className="MyCustomComponent">
            <a href={link} target={"_blank"}>Get act for this period</a>
        </div>
    );
}

